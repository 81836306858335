import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import GalleryHomeAlone from "../components/galleryHomeAlone"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi</h1>
    <p>I'm David Garcerán. I was born in 1994 and I live in Barcelona, Spain. I'm a photographer and curator. I develop software for work.</p>
    <p>You can check my work in this page.</p>
    <p>You can contact me through email to <span class='bold'>admin [at] contrafotografia.com</span>, feel free to chat with me there.</p>
    <div class="gallery full">
    	<GalleryHomeAlone />
    </div>
    <p>These pictures are part of my work for the project <a href="https://docs.google.com/presentation/d/19heXZDAOf7ydHq5UqfMlt9KEijFRleufj3vOElNcwaU" target="__blank">Pandemia</a> (2020), where I experimented at home with old chemicals and long exposures to create my diary of the quarantine.</p>
  	<hr />
    <p>I found a camera on a lake’s shore when I was a kid. I rediscovered the joy of freezing moments in time in 2014, when I started to steal rolls of film. I never understood why everyone said it was so expensive to take photos. It’s not a matter of price, it’s more like a matter of time and interest. Tons of constant unfinished photos are the burden for everyone who tries this art. Not finding the connection between two photos is one of the biggest frustrations of a photographer. And I don’t even consider myself a photographer! It stops you from taking photos, or makes you take bad ones in an attempt to fill a void.</p>
    <p>The photos you can see here have no connections between them, they feel unfinished. I like them, but they still don’t have a place where they  belong, and alone by themselves they don’t make any sense. I tried  documenting punk around the world for more than seven years, the  protests I was able to attend the last few years, the Holy Week in different parts of the Iberian Peninsula and other Christian celebrations, towns in the Pyrenees, and then my hometown (Barcelona’s suburbs) and the changes produced in an urban display that is totally transformed every time I go back to visit. I used to know the forest in front of my mom’s house better than any other place; but now a big part of it is going to disappear under the greedy hands of  real estate companies. A new airport is planned and a specific tech area is prepared to be built very near - while this happens the inhabitants of these cities can’t afford a place to live. </p>
    <p>The camera feels like an object that is a bridge between a hobby and a  machine for protest. It’s a way to show what I like or enjoy in life and also what worries me. I hope that in the future, the way every piece of this work is put together makes sense. But until then, I also hope this small amount of photos can give you insight on my work of the last eight years.</p>
    <p>Text for the <a href='https://artisticindecency.com/david-garcia' target='__blank'>Bureau of Artistic Indecency</a>.</p>
  </Layout>
);

export default IndexPage
